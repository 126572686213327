import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import pdf from './assets/servicios.pdf';

const initialState = {
	mensaje: '',
	nombre: '',
	asunto: '',
	email: '',
};
const Main = () => {
	init('user_PJavVLrg00GK5wvJpIhDc');

	const [estilo, setestilo] = useState('cont animate__animated animate__bounce animate__fadeInLeft');

	useEffect(() => {
		setTimeout(function () {
			setestilo('animate__animated animate__backOutRight');
		}, 6500);
	}, []);

	const [form, setform] = useState({ mensaje: '', nombre: '', asunto: '', email: '' });
	const [loading, setloading] = useState(false);
	const [succes, setsucces] = useState(false);
	const { mensaje, nombre, asunto, email } = form;

	const handleChange = (e) => {
		setform({
			...form,
			[e.target.name]: e.target.value,
		});
		console.log(form);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('object');
		setloading(true);

		emailjs.send('service_xuf7gha', 'template_aq2jfej', form).then(
			function (response) {
				console.log('SUCCESS!', response.status, response.text);
				setloading(false);
				setsucces(true);
			},
			function (error) {
				console.log('FAILED...', error);
			}
		);

		// emailjs.send('service_tqfqwwl', 'template_g9kjjb7', form).then(
		// 	function (response) {
		// 		console.log('SUCCESS!');
		// 	},
		// 	function (error) {
		// 		console.log('FAILED...');
		// 	}
		// );
	};

	return (
		<>
			<header id="header" className="fixed-top ">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					{/* <!-- <h1 className="logo"><a href="index.html">Dewi</a></h1> --> */}
					<a href="#" className="logo">
						<img src="assets/img/ROBERTlogo.png" alt="SEGPI" width="40%" className="img-fluid" />
					</a>

					<nav className="nav-menu d-none d-lg-block">
						<ul>
							<li className="active">
								<a href="#">Inicio</a>
							</li>
							<li>
								<a href="#about ">Nosotros</a>
							</li>
							<li>
								<a href="#services ">Servicios</a>
							</li>
							<li>
								<a href="#portfolio ">Portafolio</a>
							</li>

							<li>
								<a href="#contact ">Contacto</a>
							</li>
						</ul>
					</nav>
				</div>
			</header>
			<section id="hero">
				<div className="hero-container" data-aos="fade-up" data-aos-delay="150">
					<h1> Inspira seguridad</h1>
					<h2>La seguridad es el arte de preservar vidas</h2>
					{/* <div className="d-flex">
						<a
							href="https://youtu.be/xx0DtyhzKVk?t=5"
							className="venobox btn-watch-video"
							data-vbtype="video"
							data-autoplay="true"
						>
							{' '}
							Mira el video <i className="icofont-play-alt-2"></i>
						</a>
					</div> */}
				</div>
			</section>

			<main id="main">
				<section id="about" className="about">
					<div className="container" data-aos="fade-up">
						<div className="row">
							<div
								className="col-lg-6 video-box align-self-baseline"
								data-aos="zoom-in"
								data-aos-delay="100"
							>
								<img src="assets/img/ytimage.jpeg" className="img-fluid" alt="" />
								<a
									href="https://youtu.be/xx0DtyhzKVk?t=5"
									className="venobox play-btn mb-4"
									data-vbtype="video"
									data-autoplay="true"
								></a>
							</div>

							<div className="col-lg-6 pt-3 pt-lg-0 content">
								<h3>CORPORATIVO SEGPI</h3>
								<p className="font-italic">
									Corporativo SEGPI, Suministros Industriales de Protección S.A de C.V. Es una empresa
									comprometida con sus clientes; donde trabajamos día con día en un proceso de mejora
									continua en donde participan todos nuestros colaboradores.
								</p>
								<ul>
									<li>
										<i className="bx bx-check-double"></i> Honestidad
									</li>
									<li>
										<i className="bx bx-check-double"></i> Profesionalismo
									</li>
									<li>
										<i className="bx bx-check-double"></i> Confianza
									</li>
									<li>
										<i className="bx bx-check-double"></i> Competitividad{' '}
									</li>
								</ul>
								<p>
									En Corporativo SEGPI usamos la verdad como herramienta para ganarnos la confianza y
									credibilidad de nuestros clientes y sin dejar a un lado la competitividad de cada
									proyecto que realizamos teniendo en mente que se debe ser el mejor exigiendonos lo
									mejor.
								</p>
								<p>
									Un rasgo característico de nosotros es el profesionalismo y la confianza porque
									sabemos que van de la mano a la hora que la empresa trabaja dia a dia en fortalecer
									estos valores y realizar los proyectos con total compromiso, mesura y
									responsabilidad.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section id="about-boxes" className="about-boxes">
					<div className="container" data-aos="fade-up">
						<div className="row">
							<div
								className="col-lg-4 col-md-6 d-flex align-items-stretch"
								data-aos="fade-up"
								data-aos-delay="100"
							>
								<div className="card">
									<img src="assets/img/about-boxes-1.jpg" className="card-img-top" alt="..." />
									<div className="card-icon">
										<i className="ri-brush-4-line"></i>
									</div>
									<div className="card-body">
										<h5 className="card-title">
											<a>Misión</a>
										</h5>
										<p className="card-text">
											Consolidarnos y posicionarnos como una empresa líder en el sector de la
											seguridad y protección industrial, manteniendo un enfoque moderno y dinámico
											para dar solución a las necesidades de nuestros clientes.
										</p>
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 col-md-6 d-flex align-items-stretch"
								data-aos="fade-up"
								data-aos-delay="200"
							>
								<div className="card">
									<img src="assets/img/about-boxes-2.jpg" className="card-img-top" alt="..." />
									<div className="card-icon">
										<i className="ri-calendar-check-line"></i>
									</div>
									<div className="card-body">
										<h5 className="card-title">
											<a>Valores</a>
										</h5>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Honestidad
											</li>
											<li>
												<i className="ri-check-double-line"></i> Profesionalismo
											</li>
											<li>
												<i className="ri-check-double-line"></i> Confianza
											</li>
											<li>
												<i className="ri-check-double-line"></i> Competitividad
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 col-md-6 d-flex align-items-stretch"
								data-aos="fade-up"
								data-aos-delay="300"
							>
								<div className="card">
									<img src="assets/img/about-boxes-3.jpg" className="card-img-top" alt="..." />
									<div className="card-icon">
										<i className="ri-movie-2-line"></i>
									</div>
									<div className="card-body">
										<h5 className="card-title">
											<a>Visión</a>
										</h5>
										<p className="card-text">
											Generar un vínculo de confianza con nuestros clientes con el objetivo de
											desarrollarnos de manera conjunta, generando soluciones basadas en la
											normatividad.{' '}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="features" className="features">
					<div className="container" data-aos="fade-up">
						<div className="section-title">
							<h2>Productos</h2>
							<p>Nuestros Productos</p>
						</div>

						<ul className="nav nav-tabs row d-flex">
							<li className="nav-item col-3">
								<a className="nav-link active show" data-toggle="tab" href="#tab-1">
									<i className="fas fa-sign"></i>
									<h4 className="d-none d-lg-block">Señalamientos</h4>
								</a>
							</li>
							<li className="nav-item col-3">
								<a className="nav-link" data-toggle="tab" href="#tab-2">
									<i className="fas fa-fire-extinguisher"></i>
									<h4 className="d-none d-lg-block">Extintores</h4>
								</a>
							</li>
							<li className="nav-item col-3">
								<a className="nav-link" data-toggle="tab" href="#tab-3">
									<i className="fas fa-hard-hat"></i>
									<h4 className="d-none d-lg-block">Equipo de Seguridad</h4>
								</a>
							</li>
							<li className="nav-item col-3">
								<a className="nav-link" data-toggle="tab" href="#tab-4">
									<i className="fas fa-first-aid"></i>
									<h4 className="d-none d-lg-block">Equipo de Emergencia</h4>
								</a>
							</li>
							<li className="nav-item col-3 mt-3">
								<a className="nav-link" data-toggle="tab" href="#tab-5">
									<i className="fab fa-empire"></i>
									<h4 className="d-none d-lg-block">Sistemas de Detección y Extinción</h4>
								</a>
							</li>

							<li className="nav-item col-3 mt-3">
								<a className="nav-link" data-toggle="tab" href="#tab-6">
									<i className="fas fa-bullhorn"></i>
									<h4 className="d-none d-lg-block">Accesorios</h4>
								</a>
							</li>
							<li className="nav-item col-3 mt-3">
								<a className="nav-link" data-toggle="tab" href="#tab-7">
									<i className="fab fa-free-code-camp"></i>
									<h4 className="d-none d-lg-block">Equipo de Bombero</h4>
								</a>
							</li>
						</ul>

						<div className="tab-content">
							<div className="tab-pane active show" id="tab-1">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Señalamientos.</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Proteccion Civil
											</li>
											<li>
												<i className="ri-check-double-line"></i> Contra Incendios
											</li>
											<li>
												<i className="ri-check-double-line"></i> Restrictivas
											</li>
											<li>
												<i className="ri-check-double-line"></i> Obligatorias
											</li>
											<li>
												<i className="ri-check-double-line"></i> Informativas
											</li>
											<li>
												<i className="ri-check-double-line"></i> Precautorias
											</li>
										</ul>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/señalamientos.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>
							<div className="tab-pane" id="tab-2">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Extintores</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Agentes Limpios CO2.
											</li>
											<li>
												<i className="ri-check-double-line"></i> Agentes Especiales
											</li>
											<li>
												<i className="ri-check-double-line"></i> Agentes Húmedos
											</li>
											<li>
												<i className="ri-check-double-line"></i> Agentes Limpios HFC
											</li>
											<li>
												<i className="ri-check-double-line"></i> Agentes Secos
											</li>
										</ul>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/extintores.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>
							<div className="tab-pane" id="tab-3">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Equipo de Seguridad</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Cono naranja
											</li>
											<li>
												<i className="ri-check-double-line"></i> Trafitambo{' '}
											</li>
											<li>
												<i className="ri-check-double-line"></i> Banderín
											</li>
											<li>
												<i className="ri-check-double-line"></i> Banderola
											</li>
											<li>
												<i className="ri-check-double-line"></i> Boya
											</li>
											<li>
												<i className="ri-check-double-line"></i> Casaca para brigadas (varios
												colores)
											</li>
											<li>
												<i className="ri-check-double-line"></i>Chaleco para brigadas de algodón
												(varios colores){' '}
											</li>
											<li>
												<i className="ri-check-double-line"></i> Brazalete para brigadas (varios
												colores)
											</li>
											<li>
												<i className="ri-check-double-line"></i> Chaleco de malla
											</li>
											<li>
												<i className="ri-check-double-line"></i> Casco de seguridad
											</li>
											<li>
												<i className="ri-check-double-line"></i> Cinta “PROHIBIDO EL PASO”
											</li>
											<li>
												<i className="ri-check-double-line"></i> Cinta “PELIGRO”{' '}
											</li>
											<li>
												<i className="ri-check-double-line"></i> Cinta antiderrapante
											</li>
											<li>
												<i className="ri-check-double-line"></i>Malla naranja{' '}
											</li>
											<li>
												<i className="ri-check-double-line"></i> Cinta doble cara
											</li>
											<li>
												<i className="ri-check-double-line"></i> Calzado de seguridad
											</li>
										</ul>
										<p className="font-italic">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
											tempor incididunt ut labore et dolore magna aliqua.
										</p>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/equipo.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>
							<div className="tab-pane" id="tab-4">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Equipo de Emergencia</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Botiquín industrial
											</li>
											<li>
												<i className="ri-check-double-line"></i> Botiquín tipo mochila
											</li>
											<li>
												<i className="ri-check-double-line"></i> Botiquín tipo cangurera
											</li>
											<li>
												<i className="ri-check-double-line"></i> Lámpara de emergencia
											</li>
											<li>
												<i className="ri-check-double-line"></i> Camilla rígida
											</li>
										</ul>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/emergencia.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>

							<div className="tab-pane" id="tab-5">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Sistemas de Detección y Extinción</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Rociadores
											</li>
											<li>
												<i className="ri-check-double-line"></i> Boquillas
											</li>
											<li>
												<i className="ri-check-double-line"></i> Gas FM-200
											</li>
											<li>
												<i className="ri-check-double-line"></i> Estación manual
											</li>
											<li>
												<i className="ri-check-double-line"></i> Equipo de bombeo
											</li>
											<li>
												<i className="ri-check-double-line"></i> Válvula
											</li>
											<li>
												<i className="ri-check-double-line"></i> Panel de alarma
											</li>
											<li>
												<i className="ri-check-double-line"></i> Detectores de Humo
											</li>
											<li>
												<i className="ri-check-double-line"></i> Hidrantes
											</li>
										</ul>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/deteccion.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>

							<div className="tab-pane" id="tab-6">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Accesorios</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Funda cubre exintor
											</li>
											<li>
												<i className="ri-check-double-line"></i> Funda cubre hidrante
											</li>
											<li>
												<i className="ri-check-double-line"></i> Gabinete para extintor
											</li>
											<li>
												<i className="ri-check-double-line"></i> Gabinete para traje de bombero
											</li>
											<li>
												<i className="ri-check-double-line"></i> Gabinete para hidrante
											</li>
											<li>
												<i className="ri-check-double-line"></i> Portaextintor cilíndirco
											</li>
											<li>
												<i className="ri-check-double-line"></i> Portaextintor media luna
											</li>
											<li>
												<i className="ri-check-double-line"></i> Portaextintor cuadrado
											</li>
										</ul>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/accesorios.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>

							<div className="tab-pane" id="tab-7">
								<div className="row">
									<div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
										<h3>Equipo de Bombero</h3>
										<ul>
											<li>
												<i className="ri-check-double-line"></i> Pantalón
											</li>
											<li>
												<i className="ri-check-double-line"></i> Chaquetón
											</li>
											<li>
												<i className="ri-check-double-line"></i> Casco
											</li>
											<li>
												<i className="ri-check-double-line"></i> Guantes
											</li>
											<li>
												<i className="ri-check-double-line"></i> Hacha
											</li>
											<li>
												<i className="ri-check-double-line"></i> Capucha
											</li>
											<li>
												<i className="ri-check-double-line"></i> Pala
											</li>
											<li>
												<i className="ri-check-double-line"></i> Botas
											</li>
										</ul>
									</div>
									<div className="col-lg-6 order-1 order-lg-2 text-center">
										<img src="assets/img/bomberos.jpg" alt="" className="img-fluid" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="services" className="services section-bg">
					<div className="container" data-aos="fade-up">
						<div className="section-title">
							<h2>Servicios</h2>
							<p>Nuestros Servicios</p>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="200">
							<div className="col-md-6">
								<div className="icon-box">
									<i className="fas fa-tools"></i>
									<h4>
										<a href="#">Mantenimiento preventivo y correctivo</a>
									</h4>
									<p>
										Revisión y limpieza que garanticen el buen funcionamiento y fiabilidad de las
										instalaciones y equipos.
									</p>
								</div>
							</div>
							<div className="col-md-6 mt-4 mt-md-0">
								<div className="icon-box">
									<i className="fas fa-file-signature"></i>
									<h4>
										<a href="#">Consultoría en Seguridad e Higiene</a>
									</h4>
									<p>
										Planear, desarrollar y evaluar la estrategia de prevención de accidentes, con
										una filosofía de mejora continua.
									</p>
								</div>
							</div>

							<div className="col-md-6 mt-4 mt-md-0">
								<div className="icon-box">
									<i className="fas fa-hard-hat"></i>
									<h4>
										<a href="#">Gestoría en materia de Protección Civil</a>
									</h4>
									<p>
										Análisis de riesgos, gestión del Programa Interno y Específico de Protección
										Civil.
									</p>
								</div>
							</div>

							<div className="col-md-6 mt-4 mt-md-0">
								<div className="icon-box">
									<i className="fas fa-paste"></i>
									<h4>
										<a href="#">Desarrollo de Normas STPS</a>
									</h4>
									<p>
										Análisis, evaluación, desarrollo y mejora de los requerimientos Normativos de la
										STPS.
									</p>
								</div>
							</div>

							<div className="col-md-6 mt-4 mt-md-0">
								<div className="icon-box">
									<i className="fas fa-users"></i>
									<h4>
										<a href="#">Capacitación a Brigadas de Seguridad</a>
									</h4>
									<p>
										Promover una cultura de seguridad en las organizaciones y sus integrantes.
										Contamos con instructores certificados con validez ante la STPS y Protección
										Civil.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="portfolio" className="portfolio">
					<div className="container" data-aos="fade-up">
						<div className="section-title">
							<h2>Portafolio</h2>
							<p>Echa un vistazo a nuestro Portafolio</p>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
							<div className="col-lg-12 d-flex justify-content-center">
								<ul id="portfolio-flters">
									<li data-filter="*" className="filter-active">
										Todo
									</li>
									<li data-filter=".filter-app">Certificación de Grúas</li>
								</ul>
							</div>
						</div>

						<div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
							<div className="col-lg-4 col-md-6 portfolio-item filter-app">
								<img src="assets/img/portfolio/4.JPG" className="img-fluid" height="50px" alt="" />
								<div className="portfolio-info">
									<h4>Certificación de Grúas</h4>
									{/* <p>App</p> */}
									<a
										href="assets/img/portfolio/4.JPG"
										data-gall="portfolioGallery"
										className="venobox preview-link"
										title="App 1"
									>
										<i className="bx bx-plus"></i>
									</a>
									{/* <a href="portfolio-details.html" className="details-link" title="More Details">
										<i className="bx bx-link"></i>
									</a> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 portfolio-item filter-app">
								<img src="assets/img/portfolio/2.JPG" className="img-fluid" alt="" />
								<div className="portfolio-info">
									<h4>Certificación de Grúas</h4>
									{/* <p>App</p> */}
									<a
										href="assets/img/portfolio/2.JPG"
										data-gall="portfolioGallery"
										className="venobox preview-link"
										title="App 2"
									>
										<i className="bx bx-plus"></i>
									</a>
									{/* <a href="portfolio-details.html" className="details-link" title="More Details">
										<i className="bx bx-link"></i>
									</a> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 portfolio-item filter-app">
								<img src="assets/img/portfolio/6.JPG" className="img-fluid" alt="" />
								<div className="portfolio-info">
									<h4>Certificación de Grúas</h4>
									{/* <p>App</p> */}
									<a
										href="assets/img/portfolio/6.JPG"
										data-gall="portfolioGallery"
										className="venobox preview-link"
										title="App 3"
									>
										<i className="bx bx-plus"></i>
									</a>
									{/* <a href="portfolio-details.html" className="details-link" title="More Details">
										<i className="bx bx-link"></i>
									</a> */}
								</div>
							</div>
						</div>
					</div>
				</section>

				<a
					className="whats animate__animated animate__bounce animate__bounceInDown"
					target="_blank"
					href="https://wa.me/5215624576281?text=Me%20encuentro%20interesado%20en%20solicitar%20una%20cotizacion"
					target="_blank"
				>
					<i className="fab fa-whatsapp"></i>
				</a>

				<a className={`${estilo}`}>
					<strong>Descarga nuestra carta de servicios!</strong>
				</a>

				<a
					className="pdf animate__animated animate__bounce animate__bounceInDown"
					target="_blank"
					// href="https://wa.me/5215624576281?text=Me%20encuentro%20interesado%20en%20solicitar%20una%20cotizacion"
					// target="_blank"
					href={pdf}
					download
				>
					<i class="fas fa-file-pdf"></i>
				</a>
				<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">
						<div className=" section-title ">
							<h2>Contacto</h2>
							<p>Contáctanos</p>
						</div>

						<div className="row ">
							<div className="col-lg-6 ">
								<div className="row ">
									<div className="col-md-12 ">
										<div className="info-box ">
											<i className="bx bx-map "></i>
											<h3>Dirección</h3>
											<p>
												<a href="https://goo.gl/maps/EXVDGYvH2GeJfFgA6 ">
													Cerrada de Trigo, Mz 4, Lt 1, Col Xalpa, Alcaldia Iztapalapa, CP
													09640
												</a>
											</p>
										</div>
									</div>
									<div className="col-md-6 ">
										<div className="info-box mt-4 ">
											<i className="bx bx-envelope "></i>
											<h3>Email</h3>
											<p>
												<a href="mailto:carlossena.segpi@gmail.com ">
													carlossena.segpi@gmail.com
												</a>
											</p>
											<br />
											<p>
												<a href="mailto:javiersena.segpi@gmail.com ">
													javiersena.segpi@gmail.com
												</a>
											</p>
										</div>
									</div>
									<div className="col-md-6 ">
										<div className="info-box mt-4 ">
											<i className="bx bx-phone-call "></i>
											<h3>Teléfono</h3>

											<p>
												<a href="tel:52-1-56-2457-6281 ">(56) 2457 6281</a>
											</p>

											<p>
												<a href="tel:52-1-55-7422-6655 ">(55) 7422 6655</a>
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-6 mt-4 mt-lg-0 ">
								<form onSubmit={handleSubmit}>
									<div className="form-row ">
										<div className="col-md-5 form-group ">
											<input
												type="text"
												className="form-control"
												name="nombre"
												placeholder="Nombre"
												onChange={(e) => handleChange(e)}
												value={nombre}
												// onChange={(e) => handleChange(e)}
												// value={nombre}
												// type="text"
												// name="nombre"
												// className="form-control "
												// placeholder="Tu Nombre aqui "
												// data-rule="minlen:4 "
												// data-msg="Please enter at least 4 chars "
											/>
											<div className="validate "></div>
										</div>
										<div className="col-md-5 form-group ">
											<input
												onChange={(e) => handleChange(e)}
												value={email}
												type="email"
												className="form-control "
												name="email"
												placeholder="Ahora tu email "
												data-rule="email "
												data-msg="Please enter a valid email "
											/>
											<div className="validate "></div>
										</div>
									</div>
									<div className="form-group ">
										<input
											onChange={(e) => handleChange(e)}
											value={asunto}
											type="text"
											className="form-control "
											name="asunto"
											placeholder="Asunto "
											data-rule="minlen:4 "
											data-msg="Please enter at least 8 chars of subject "
										/>
										<div className="validate "></div>
									</div>
									<div className="form-group ">
										<textarea
											onChange={(e) => handleChange(e)}
											value={mensaje}
											className="form-control "
											name="mensaje"
											rows="5"
											data-rule="required "
											data-msg="Dinos tus deseos "
											placeholder="Dinos tus deseos "
										></textarea>
										<div className="validate "></div>
									</div>
									<div className="mb-3 ">
										{loading ? <div className="loading alert-danger ">Cargando...</div> : null}
										<div className="error-message "></div>
										{succes ? (
											<div className="sent-message alert-success ">Tu mensaje fue enviado!</div>
										) : null}
									</div>
									<div className="text-center ">
										<button className="btn" type="submit">Enviar</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</main>

			<footer id="footer ">
				<div className="footer-top ">
					<div className="container ">
						<div className="row ">
							<div className="col-lg-4 col-md-6 ">
								<div className="footer-info ">
									<h3>
										{' '}
										<stSrong>Corporativo</stSrong> SEGPI{' '}
									</h3>

									<div className="social-links mt-3 ">
										{/* <!-- <a href="# " className="twitter "><i className="bx bxl-twitter "></i></a> --> */}
										<a
											href="https://www.facebook.com/Corporativo-SEGSPI-Suministros-Industriales-de-Protecci%C3%B3n-104700584820196 "
											target="_blank "
											className="facebook "
										>
											<i className="bx bxl-facebook "></i>
										</a>
										{/* <!-- <a href="# " className="instagram "><i className="bx bxl-instagram "></i></a> --> */}
										{/* <!-- <a href="# " className="google-plus "><i className="bx bxl-skype "></i></a> --> */}
										<a
											href="https://www.linkedin.com/company/corporativo-segpi-suministros-industriales-de-proteccion-sa-de-cv/ "
											target="_blank "
											className="linkedin "
										>
											<i className="bx bxl-linkedin "></i>
										</a>
									</div>
								</div>
							</div>

							<div className="col-lg-4 col-md-6 footer-links ">
								<h4>Links</h4>
								<ul>
									<li>
										<i className="bx bx-chevron-right "></i> <a href="# ">Inicio</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i> <a href="#about ">Nosotros</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i> <a href="#services">Servicios</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i> <a href="# ">Terminos y Condiciones</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i> <a href="# ">Política de Privacidad</a>
									</li>
								</ul>
							</div>

							<div className="col-lg-4 col-md-6 footer-links ">
								<h4>Nuestros Servicios</h4>
								<ul>
									<li>
										<i className="bx bx-chevron-right "></i>{' '}
										<a href="#services ">Mantenimiento preventivo y correctivo</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i>{' '}
										<a href="#services ">Consultoría en Seguridad e Higiene</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i> <a href="#services ">Capacitación</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i>{' '}
										<a href="#services ">Gestoría en materia de Protección Civil</a>
									</li>
									<li>
										<i className="bx bx-chevron-right "></i>{' '}
										<a href="#services ">Desarrollo de Normas STPS</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>

			<a href="#about" className="back-to-top ">
				<i className="ri-arrow-up-line "></i>
			</a>
			<div id="preloader "></div>
		</>
	);
};

export default Main;
